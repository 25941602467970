<template>
  <div class="page-container">
    <Tabs style="width:100%;height:100%" :value="currentTabl">
      <TabPane label="用水量上报" name="water">
        <Water></Water>
      </TabPane>
      <TabPane label="销售上报" name="sell">
        <Sell></Sell>
      </TabPane>
    </Tabs>
  </div>
</template>
<script>
import Water from "./water";
import Sell from "./sell"
export default {
  components: { Water,Sell },
  data() {
    return {
      currentTabl: "water",
    };
  },
};
</script>
<style lang='less' scoped>
@import url("./index.less");
</style>