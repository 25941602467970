<template>
  <div class="tab-cotanier">
    <x-table
      :no-data-text="CA('userProduct_check') ? '暂无数据' : '暂无数据查询权限'"
      :columns="columns"
      :data="data"
      :loading="loading"
      :config="config"
      @add="add"
      @search="search"
      @page-change="pageChange"
      :showSummary="true"
      :summaryMethod="handleSummary"
    ></x-table>
    <!-- <div class="options-row">
      <div class="search-item">
        <span>养殖场养殖区：</span>
        <span>
          <Cascader
            transfer
            v-model="searchData.farmScene"
            :data="farmScenes"
            change-on-select
            :load-data="loadingScenes"
            @on-change='cascaderChange'
          ></Cascader>
        </span>
      </div>
      <div class="add-item">
        <Button @click="add" type="primary" icon="md-add">新增上报</Button>
      </div>
    </div> -->

    <Modal
      @on-visible-change="modalVisibleChange"
      v-model="modalDataShow"
      title="销售上报"
    >
      <Form ref="form" :model="form" :rules="rules" :label-width="120">
        <FormItem label="养殖场" prop="farmId">
          <Select transfer v-model="form.farmId">
            <Option
              v-for="farm in farmScenes"
              :key="farm.value"
              :value="farm.value"
            >
              {{ farm.label }}
            </Option>
          </Select>
          <!-- <Cascader
            transfer
            v-model="form.farmName"
            :data="farmScenes"
            change-on-select
          ></Cascader> -->
        </FormItem>
        <FormItem label="地区" prop="region">
          <Input v-model="form.region"></Input>
        </FormItem>
        <FormItem label="买主" prop="buyer">
          <Input v-model="form.buyer"></Input>
        </FormItem>
        <FormItem label="数量" prop="amount">
          <Input v-model="form.amount">
            <span slot="append">kg</span>
          </Input>
        </FormItem>
        <FormItem label="价格" prop="price">
          <Input v-model="form.price"> </Input>
        </FormItem>
      </Form>
      <div slot="footer" style="text-align: center">
        <Button @click="submit" :loading="formLoading" type="primary"
          >提交</Button
        >
        <Button @click="modalDataShow = false">取消</Button>
      </div>
    </Modal>
    <div style="margin-top: 150px">
      <Table :columns="columns" :data="data"></Table>
    </div>
  </div>
</template>

<script>
import { Divider } from "view-design";
export default {
  data() {
    return {
      searchData: {
        farmScene: [],
      },
      search_data: {},
      page: {
        pageNo: 1,
        pageSize: 12,
        total: 0,
      },
      // 基地场景
      farmScenes: [],
      // 基地索引
      farmMap: new Map(),
      modalDataShow: false,
      loading: false,
      form: {
        farmId: "",
        region: "",
        buyer: "",
        amount: "",
        price: "",
        id: "",
      },
      rules: {
        farmId: [{ required: true, message: "请选择养殖场" }],
        region: [{ required: true, message: "请填写地区" }],
        buyer: [{ required: true, message: "请填写买主" }],
        amount: [{ required: true, message: "请填写数量" }],
        price: [{ required: true, message: "请填写价格" }],
      },
      formLoading: false,
      columns: [
        {
          title: "养殖场",
          key: "farmName",
          render: (h, { row }) => {
            let str = this.farmMap.get(row.farmId + "") || "";
            return <span>{str}</span>;
          },
        },
        {
          title: "地区",
          key: "region",
        },
        {
          title: "买主",
          key: "buyer",
        },
        {
          title: "数量",
          key: "amount",
        },
        {
          title: "价格",
          key: "price",
        },
        {
          title: "操作",
          width: 220,
          render: (h, { row }) => {
            return (
              <div>
                {this.CA("sell_edit") && (
                  <a onClick={() => this.edit(row)}>编辑</a>
                )}
                {this.CA("sell_del") && (
                  <Poptip
                    confirm
                    transfer
                    title="确定删除吗?"
                    style="margin-left:10px"
                    on-on-ok={() => this.del(row.id)}
                  >
                    <a>删除</a>
                  </Poptip>
                )}
              </div>
            );
          },
        },
      ],
      data: [],
      farmId: "",
    };
  },
  methods: {
    // 获取养殖区
    // loadingScenes(item, callback) {
    //   item.loading = true;
    //   this.$post(this.$api.PRODUCTION_AREA.LIST, {
    //     farmId: item.value,
    //     pageNo: 1,
    //     pageSize: 9000,
    //     siteType: "2",
    //   })
    //     .then((res) => {
    //       if (!res.list || res.list.length === 0) {
    //         item.children = [
    //           {
    //             disabled: true,
    //             value: -1,
    //             label: "无",
    //           },
    //         ];
    //       } else {
    //         item.children = res.list.map((item) => ({
    //           value: item.id,
    //           label: item.name,
    //         }));
    //       }
    //     })
    //     .finally(() => {
    //       item.loading = false;
    //       callback();
    //     });
    // },
    add() {
      this.modalDataShow = true;
    },
    // 获取基地场景
    getFarmScene() {
      return new Promise((resolve) => {
        this.$post(this.$api.BASE_MANAGE.SIMPLE_FARM_LIST, {
          pageNo: 1,
          pageSize: 9999,
        })
          .then((res) => {
            let farmScenes = [];
            for (let i = 0; i < res.list.length; i++) {
              let item = res.list[i];
              this.farmMap.set(item.id + "", item.name);
              farmScenes.push({
                value: item.id,
                label: item.name,
              });
            }
            this.farmScenes = farmScenes;
            resolve();
          })
          .catch(() => {
            resolve();
          });
      });
    },
    submit() {
      this.$refs.form.validate((res) => {
        if (!res) return;
        let params = { ...this.form };
        let url;
        if (params.id) {
          url = this.$api.SELL_USAGE.EDIT;
        } else {
          url = this.$api.SELL_USAGE.ADD;
        }
        this.$post(url, params).then((res) => {
          this.$Message.success(params.id ? "编辑成功！" : "添加成功！");
          this.modalDataShow = false;
          this.getList();
        });
      });
    },
    modalVisibleChange(visible) {
      if (visible) return;
      this.$refs.form.resetFields();
      this.formLoading = false;
    },
    edit(row) {
      this.form.farmId = row.farmId;
      this.form.region = row.region;
      this.form.buyer = row.buyer;
      this.form.amount = row.amount;
      this.form.price = row.price;
      this.form.id = row.id;
      this.modalDataShow = true;
    },
    del(row) {
      this.$post(this.$api.SELL_USAGE.DEL, {
        id: row,
      }).then((res) => {
        this.getList();
      });
    },
    getList() {
      this.loading = true;
      this.$post(this.$api.SELL_USAGE.LIST, {
        pageNo: this.page.pageNo,
        pageSize: this.page.pageSize,
        ...this.search_data,
      }).then((res) => {
        this.data = res.list;
        this.page.total = +res.total;
        this.loading = false;
      });
    },
    handleSummary({ columns, data }) {
      const sums = {};
      columns.forEach((column, index) => {
        const key = column.key;
        if (index === 0) {
          sums[key] = {
            key,
            value: "统计",
          };
          return;
        }
        const values = data.map((item) => Number(item[key]));
        if (column.key == "amount" || column.key == "price") {
          if (!values.every((value) => isNaN(value))) {
            const v = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            sums[key] = {
              key,
              value: v,
            };
          } else {
            sums[key] = {
              key,
              value: "",
            };
          }
        } else {
          sums[key] = {
            key,
            value: "",
          };
        }
      });
      return sums;
    },
    //养殖区下拉回调
    // cascaderChange(value){
    //   this.$post(this.$api.SELL_USAGE.LIST, {
    //     farmId:value[0]
    //   }).then((res) => {
    //     this.data = res.list;
    //   });
    // },
    search(data) {
      let obj = {
        farmId: data.area[0],
        sceneId: data.area[1],
        categoryId: data.categoryId,
        ...data,
      };
      this.search_data = obj;
      this.page.pageNo = 1;
      this.getList();
    },
    pageChange(pageNo) {
      this.page.pageNo = pageNo;
      this.getList();
    },
  },
  computed: {
    config() {
      let config = {
        filter: {
          add: {
            addBtnName: "新增消纳量",
            ca: "sell_add",
          },
          width: 220,
          filterBox: [
            {
              conditionName: "养殖场",
              component: "select",
              data: this.farmScenes,
              field: "farmId",
              defaultValue: "",
              parameterField: "value",
              showField: "label",
            },
            {
              conditionName: "地区",
              component: "input",
              field: "region",
              defaultValue: "",
            },
            {
              conditionName: "买主",
              component: "input",
              field: "buyer",
              defaultValue: "",
            },
          ],
        },
        page: this.page,
      };
      return config;
    },
  },

  mounted() {
    let _self = this;
    this.getFarmScene().then(() => {
      _self.getList();
    });
    // this.getList();
  },
};
</script>

<style lang="less" scoped>
@import url("./index.less");
</style>